import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../components/api/Url";
import CurateCard from "./CurateCard";

const SellerCurate = ({ data, onHighlightClick }) => {
  const [featureData, setFeatureData] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  
  async function getFeatures() 
  {
    try {
      console.log("Fetching features data...");
      const json = JSON.stringify({ seller_id: sellerid });
      let response = await axios.post(url + "/app/v1/api/get_seller_features_sections_final",json); //get_seller_features_sections_final
      console.log("API response:", response);
      let res = await response.data;
      console.log("curate data:", res);
      setFeatureData(res.slice(0, 20));
    } catch (error) {
     // console.error("Error submitting form:", error);
    }
  }

  useEffect(() => {
    getFeatures();
  }, []);

  const handleHighlightClick = (id) => {
   // console.log("Highlight clicked:", id);  // Log the clicked highlight ID
    onHighlightClick(id);
  };

  return (
    <div className="mt-10">
      <div className="mt-5">
        <div className="flex justify-between">
          <div className="text-center mx-auto mt-[30px]">
            <div className="text-2xl font-semibold mx-auto  flex flex-col items-center justify-center">{data?.component?.features_section?.title}

            <div className="w-[100px] h-[3px] mb-[10px] mt-3 bg-black rounded-full"></div>
            </div>
            <div className="w-28 h-1 bg-white mx-auto mt-2 rounded">
            {/* <Link to={`/o/${sellerslug}/featuresdata`} className="text-black">
            view all
          </Link> */}
            </div>
          </div>
         
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 px-20 lg:grid-cols-2 gap-16 mt-10 mb-5 justify-center items-center">
          {featureData.map((item) => (
            <div key={item.id} className="banner" onClick={() => handleHighlightClick(item.id)}>
              <CurateCard
                title={item.title}
                short_description={item.short_description}
                image={`${item.banner}`}
                featureid={`${item.id}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SellerCurate;
