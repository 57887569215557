import React, { useEffect, useState } from "react";
import { Blogcard } from "../../components/cards/Blogcard";
import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";
import axios from "axios";

const SellerBlog = ({data}) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [blogdata, setBlogdata] = useState([]);

  const getBlg = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/listofblog', json)
      .then((res) => {
      //  console.log('data blog' + JSON.stringify(res.data.message));
      setBlogdata(res.data.message.slice(0,4))
        // setBranddata([])
      })
      .catch(() => {
        setBlogdata([])
      });
  }
  useEffect(() => {
    getBlg();
  }, [])

  // console.log("Blogs", branddata);

  return (
    <div className="mt-16">
      <div className="flex justify-between items-center">
       
        {/* <Link to={`/o/${sellerslug}/blog`} className="text-blue-700">
          view all
        </Link> */}
      </div>
      <div className="text-2xl font-semibold mx-auto  flex flex-col  items-center justify-center"> <h1 className="text-left font-bold text-[22px]" 
        >{data?.component?.blog?.title}
        {/* style={{color:`${data?.component?.image_gallery?.color}`}} */}
         {/*  GGGGGG  {JSON.stringify(blogdata)}   */}
        </h1>

<div    className="w-[100px] h-[3px] mb-[10px] mt-3 bg-black rounded-full"></div>
</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10 mb-5 justify-center items-center">{/* gap-8 mt-5 */}
        {blogdata && blogdata?.map(user => (

          <Blogcard key={user.id} className="" id={user.id}
            title={user.Title}
            Blogimage={`uploads/seller/${user.Blogimage}`}
            name={"blog"} 
            autoid={user.id}
            // width={"31%"}
          />
        ))}
      </div>
    </div>
  );
};

export default SellerBlog;
