import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout2 from "./Layout2";
import SellerHome from "./pages/seller/SellerHome";
import Test from "./pages/new/Test";
import Test2 from "./pages/new/Test2";
import ShopNowPage from "./pages/shop_now/ShopNowPage";
import SellerDetails from "./pages/seller/SellerDetails";
import ProductPage from "./pages/product_page/ProductPage ";
import Layout from "./Layout";
import HomePage from "./pages/zulu_club/HomePage";
import BrandsPage from "./pages/zulu_club/BrandsPage";
import PartnersPage from "./pages/zulu_club/PartnersPage";
import AboutUsPage from "./pages/zulu_club/AboutUsPage";
import ConsumerPage from "./pages/zulu_club/ConsumerPage";

import Bussinesspage from "./pages/zulu_club/Bussinesspage";
import Business from "./components/Zulu_club/home_page_compo/Business";
import SellerPanel from "./pages/seller/SellerPanel";
import Shopnow from "./pages/template1/Shopnow";
import Blog from "./pages/template1/Blog";
import Aboutus from "./pages/template1/Aboutus";
import Categoryall from "./pages/template1/Categoryall";
import Brandall from "./pages/template1/Brandall";
import Categoriesall from "./pages/template1/Categoriesall";
import Features_section from "./pages/template1/Features_section";
import AllModel3D from "./components/model3D/AllModel3D";
import Video from "./components/model3D/Video";
import ViewAllCategories from "./components/categaries_menu/ViewAllCategories";
import ViewAllOutlets from "./components/cards/ViewAllOutlets";
import AllBrands from "./components/brands/AllBrands";
import AllHighlights from "./components/highlights/AllHighlights";
import AllFeatures from "./components/features/AllFeatures";
import AllBlogs from "./components/blogs/AllBlogs";
import AllTestimonials from "./components/testimonials/AllTestimonials";
import AllVideo from "./components/model3D/AllVideo";
import Layoutconsumer from "./Layoutconsumer";
import Sellerb2blist from "./pages/seller/Sellerb2blist";
import SellerLayout from "./pages/template1/SellerLayout";
import SellerFeaall from "./pages/template1/SellerFeaall";
import { CartProvider } from './components/categaries_menu/CartContext';

import { Provider } from 'react-redux';
import store from "./components/template_one/redux/store";
import Features_section_individual from "./pages/template1/Features_section_individual";
import Seller_product from "./pages/template1/Seller_product";

import ProductCategoryPage from "./components/categaries_menu/ProductCategoryPage";
import BannerDetails from "./pages/template1/BannerDetails";
import FeatureDetails from "./pages/template1/FeatureDetails";
import HighlightDetails from "./pages/template1/HighlightDetails";
import BlogDetails from "./pages/template1/BlogDetails";
import ProductDetailPage from "./components/categaries_menu/ProductDetailPage";
import ReduxPage from "./pages/template1/ReduxPage";
import ReduxDashboard from "./pages/template1/ReduxDashboard";
import { ProductProvider } from "./components/categaries_menu/ProductContext";
import AllProductCategory from "./components/categaries_menu/AllProductCategoryPage";
import ShoppingCart from "./components/Zulu_club/header/ShoppingCart";
import Profilepage from "./pages/seller/Profilepage";
import { ToastContainer } from "react-toastify";
import CheckoutPage from "./components/checkout/CheckoutPage";
import OrderSuccess from "./components/checkout/OrderSuccess";
import Order from "./components/checkout/Order";
import { LoadingProvider } from "./components/categaries_menu/LoadingContext";
import Model3D2 from "./components/model3D/Model3D2";
import Booking from "./pages/zulu_club/Booking";
import HighlightDetail from "./components/highlights/HighlightDetail";
import SellerProductPage from "./pages/template1/SellerProductPage";
import SellerProductDetail from "./pages/template1/SellerProductDetail";
import { SellerProductProvider } from "./components/categaries_menu/SellerProductContext";
import { SellerCartProvider } from "./components/categaries_menu/SellerCartContext";
import Aboutuscomponent from "./components/template_one/Aboutuscomponent";
import AboutUs from "./components/about_us/AboutUs";
import SellerContactUs from "./pages/template1/SellerContactUs";
import SellerPrivacyPolicy from "./pages/template1/SellerPrivacyPolicy";
import SellerTermsConditions from "./pages/template1/SellerTermsConditions";
import SellerReturnPolicy from "./pages/template1/SellerReturnPolicy";
import SellerUserProfile from "./pages/template1/SellerUserProfile";
import SellerShoppingCart from "./components/Zulu_club/header/SellerShoppingCart";
import SellerCheckoutPage from "./components/checkout/SellerCheckoutPage";
import UserLogin from "./pages/template1/UserLogin";
import BlogSellerDetails from "./pages/template1/BlogSellerDetails";
import SellerOrderSuccess from "./pages/template1/SellerOrderSuccess";


/* <Bussinesspage /> */
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "business",
        element: <HomePage />,
      },
      {
        path: "business-page",
        element: <Business />,
      },
      {
        path: "brands",
        element: <BrandsPage />,
      },
      {
        path: "partners",
        element: <PartnersPage />,
      },
      {
        path: "",
        element: <AboutUsPage />,
      },
     
      {
        path: "consumer",
        element: <ConsumerPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Layoutconsumer />,
    children: [
      {
      /*   path: "SellerHome", */
      path: "o",
        element: <SellerHome />,
      },
      {
        path: "booking",
        element: <Booking />,
      },
      {
        path: "SellerPanel:id",
        element: <SellerPanel />,
      },
      //profile-page
      {
        path: "profile-page",
        element: <Profilepage />,
      },
      {
        path: "profile-page",
        element: <Profilepage />,
      },
      {
        path: "shopnowpage",
        element: <ShopNowPage />,
      },
      {
        path: "ProductPage ",
        element: <ProductPage />,
      },
      {
        path: "test",
        element: <Test />,
      },
      {
        path: "test2",
        element: <Test2 />,
      },
      {
        path: "All3DModels",
        element: <AllModel3D />,
      },
      {
        path: "AllVideos",
        element: <AllVideo />,
      },
      {
        path: "AllCategories",
        element: <ViewAllCategories />,
      },
      {
        path: "AllOutlets",
        element: <ViewAllOutlets />,
      },
      {
        path: "AllBrands",
        element: <AllBrands />,
      },
      {
        path: "AllHighlights",
        element: <AllHighlights />,
      },
      {
        path: "AllFeatures",
          element: <AllFeatures />,
        },
        {
          path: "AllBlogs",
        element: <AllBlogs />,
      },
      {
        path: "AllTestimonials",
        element: <AllTestimonials />,
      },
      {
        path: "productcategory/:id",
        element: <ProductCategoryPage />,
      },
      {
        path: "allproducts",
        element: <AllProductCategory />,
      },
      {
        path: "productdetail/:id",
        element: 
        <ProductDetailPage />,
      },
      {
        path: "cart",
        element: 
        <ShoppingCart  />,
      },
      {
        path: "checkout",
        element: 
        <CheckoutPage  />,
      },
      {
        path: "ordersuccess/:id",
        element: 
        <OrderSuccess  />,
      },
      {
        path: "order",
        element: 
        <Order  />,
      },
    /*   {
        path: "o",
        element: <Sellerb2blist />,
      }, */
      {
        path: "image/:id",
        element: <BannerDetails />,
        
      },
      {
        path: "highlights/:id",
        element: <HighlightDetail/>,
      },
      {
        path: "feature/:id",
        element: <FeatureDetails />,
      },
      {
        path: "Blog/:id",
        element: <BlogDetails />,
      },
      {
        path: "3d",
        element: <Model3D2 />,
      },
    ],
  },
  {
    path: "/",
    element: <SellerLayout />,
    children: [
      {
        path: "redux",
        element: <ReduxPage />,
      },
      {
        path: "reduxdashboard",
        element: <ReduxDashboard />,
      },
      {
        path: "o/:id",
        element: <SellerDetails />,
      },
      {
        path: "o/:id/profile",
        element: <SellerUserProfile />,
        
      },
      {
        path: "o/:id/shop-now",
        element: <Shopnow />,
      },
      {
        path: "o/:id/blog",
        element: <Blog />,
      },
      {
        path: "o/:id/about-us",
        element: <Aboutus />,
      },
      {
        path: "o/:id/privacy-policy",
        element: <SellerPrivacyPolicy />,
      },/* SellerTermsConditions */
      {
        path: "o/:id/terms-conditions",
        element: <SellerTermsConditions />,
      },
      {
        path: "o/:id/return-policy",
        element: <SellerReturnPolicy />,
      },
      {
        path: "o/:id/contact-us",
        element: <SellerContactUs />,
      },
      //categories-all
      {
        path: "o/:id/categories-all",
        element: <Categoryall />,
      },
      //{`/o/${sellerslug}/${item.id}/features-section`}
      {
        path: "o/:id/:ids/features-section",
        element: <Features_section />,
      },
      {
        path: "o/:id/featuresdata",
        element: <Features_section_individual />,
      },
      {
        path: "o/:id/:ids/:idss/product",
        element: <Seller_product />,
      },
      {
        path: "o/:id/featuresall",
        element: <SellerFeaall />,
      },
      {
        path: "o/:id/brand-all",
        element: <Brandall />,
      },
      {
        path: "o/:id/:ids/blog",
        element: <BlogSellerDetails />,
      },
      //Categories_all_component
      {
        path: "o/:id/categorie-all",
        element: <Categoriesall />,
      },
      {
        path: "sellerproduct/:id",
        element: <SellerProductPage />,
      },
      {
        path: "o/:id/:ids/product", /* "o/:id/:ids/product */  /* sellerproductdetail */
        element:<SellerProductDetail />,
      },
      {
        path: "o/:id/cart",
        element:
        <SellerShoppingCart />,
      },
      {
        path: "o/:id/checkout",
        element:
        <SellerCheckoutPage />,
      },
      {
        path: "o/:id/ordersuccess/:id",
        element: 
        <SellerOrderSuccess  />,
      },
      {
        path: "o/:id/login",
        element:
        <UserLogin />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadingProvider>
        <ProductProvider>
          <SellerProductProvider>
            <CartProvider> 
              <SellerCartProvider> 
                <ToastContainer position="top-right" autoClose={5000} />
                <RouterProvider router={router} />
              </SellerCartProvider>
            </CartProvider>
          </SellerProductProvider>
        </ProductProvider>
      </LoadingProvider>
    </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
