import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Card_seller_cat from "../../components/cards/Card_seller_cat";
import { useCategory } from "../../components/categaries_menu/CategoryContext";
import Load from "../../assets/images/States/Loader.gif";
import { url } from "../../components/api/Url";

const Seller_Categories_menu = ({ data }) => {
  const sellerid = localStorage.getItem("seller_id");
  const sellerslug = localStorage.getItem("seller_slug");
  const [categoriesData, setCategoriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagesDisplayed, setImagesDisplayed] = useState(0);
  const { setSelectedCategory } = useCategory();
  const timeoutRef = useRef(null);

  const getCat = async () => {
    setLoading(true);
    const json = JSON.stringify({ seller_id: sellerid });
    
    try {
      const response = await axios.post(`${url}/app/v1/api/seller_category_list`, json);
      const res = response.data;

      if (res && Array.isArray(res.message)) {
        setCategoriesData(res.message.slice(0, 8));
      } else {
        setCategoriesData([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategoriesData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCat();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // Handle image fully displayed
  const handleImageDisplayed = useCallback(() => {
    setImagesDisplayed((prev) => prev + 1);
  }, []);

  const skeletonCards = new Array(5).fill(0);

  return (
    <div className="mt-10">
      <div className="mt-5">
    

        <div className="flex justify-center md:justify-between mt-5 gap-5 md:gap-3 flex-wrap">
          {loading ? (
            skeletonCards.map((_, index) => (
              <div key={index} className="flex-shrink-0 w-[150px] sm:w-auto animate-pulse">
                <div className="bg-gray-300 h-32 w-full mb-2 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-3/4 mx-auto mb-2 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-1/2 mx-auto rounded-md"></div>
              </div>
            ))
          ) : categoriesData.length > 0 ? (
            categoriesData.map((category) => (
              <div
                key={category.zulu_category_id}
                onClick={() => handleCategoryClick(category)}
                className="cursor-pointer"
              >
                <Card_seller_cat
                  className="p-4"
                  zulu_category_name={category.zulu_category_name}
                  seller_category_name={category.seller_category_name}
                  zulu_category_image={category.zulu_category_image}
                  seller_category_image={category.seller_category_image}
                  zulu_category_id={category.zulu_category_id}
                  onImageDisplayed={handleImageDisplayed}
                />
              </div>
            ))
          ) : (
            <p>No categories to display</p>
          )}
        </div>

        {loading && (
          <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
            <img src={Load} alt="Loading..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default Seller_Categories_menu;
