import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCategory } from "../../components/categaries_menu/CategoryContext";
import { useLoading } from "../../components/categaries_menu/LoadingContext";
import { url } from "../../components/api/Url";
import CategorySlidercard from "./CategorySlidercard";

const SellerCategoriesMenu = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const { selectedCategory } = useCategory();
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { setIsLoading } = useLoading();
  const [loadedImages, setLoadedImages] = useState(0);
  const sellerid = localStorage.getItem('seller_id');

  // Flatten categories recursively (main categories + subcategories)
  const flattenCategories = (categories) => {
    let flatList = [];
    categories.forEach((category) => {
      flatList.push(category);
      if (category.children && Array.isArray(category.children)) {
        flatList = flatList.concat(flattenCategories(category.children));
      }
    });
    return flatList;
  };

  // Fetch categories for the seller
  const getSellerCategories = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    try {
      const response = await axios.post(url + "/app/v1/api/seller_category_list", json);
      let res = response.data.message;

      // Flatten categories to include subcategories
      const flattenedCategories = flattenCategories(res);
      setCategoriesData(flattenedCategories);

      // Log the categories and subcategories to the console
      console.log("Fetched Categories:", res);
      console.log("Flattened Categories:", flattenedCategories);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching seller categories:", error);
      setCategoriesData([]);
      setIsLoading(false);
    }
  };

  // Fetch category IDs from web control API
  const getWebcontrolCategories = async () => {
    const webcontrolUrl = url + "/app/v1/api/list_of_webcontroll";
    try {
      const response = await axios.post(webcontrolUrl);
      const res = response.data;

      if (Array.isArray(res.message)) {
        let categoryIds = [];
        res.message.forEach((item) => {
          categoryIds = categoryIds.concat(item.categories_id.split(","));
        });
        setCategoriesId(categoryIds);

        // Log the category IDs to the console
        console.log("Category IDs from Web Control:", categoryIds);
      } else {
        console.warn("Unexpected data structure from webcontrol:", res);
      }
    } catch (error) {
      console.error("Error fetching webcontrol categories:", error);
    }
  };

  // Image load handler to manage loading state
  const handleImageLoad = useCallback(() => {
    setLoadedImages((prev) => {
      const newCount = prev + 1;
      if (newCount === categoriesData.length) {
        setIsLocalLoading(false);
        setIsLoading(false);
      }
      return newCount;
    });
  }, [categoriesData.length, setIsLoading]);

  useEffect(() => {
    getWebcontrolCategories();
  }, []);

  useEffect(() => {
    if (categoriesId.length > 0 || selectedCategory) {
      getSellerCategories();
    }
  }, [categoriesId, selectedCategory]);

  // Log the categoriesData to the console to ensure it's available
  useEffect(() => {
    console.log("Categories Data:", categoriesData);
  }, [categoriesData]);

  return (
    <div className="mt-6">
      <div className="mt-3">
        <div className="flex overflow-x-auto custom-scrollbar space-x-8 w-[100%]">
          {categoriesData.length > 0 ? (
            categoriesData.slice(0, 6).map((category) => (
              <Link
                key={category.zulu_category_id}
                to={`/sellerproduct/${category.zulu_category_id}`}
                className="no-underline flex-shrink-0 w-[150px] sm:w-auto"
              >
                <CategorySlidercard
                  className="p-1"
                  zulu_category_name={category.zulu_category_name}
                  seller_category_name={category.seller_category_name}
                  zulu_category_image={category.zulu_category_image}
                  seller_category_image={category.seller_category_image}
                  zulu_category_id={category.zulu_category_id}
                  subcategories={category.children} // Pass subcategories
                  onImageLoad={handleImageLoad}
                />
              </Link>
            ))
          ) : (
            <p className="text-gray-500 text-lg animate-bounce flex">
              <>
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
              </>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerCategoriesMenu;
