import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useParams } from "react-router-dom";
import Card2 from "../../components/cards/Card2";
import Header from "../../components/header/Header";
import Banner1 from "../../components/cards/Banner1";
import BrandsSection from "../../components/brands/BrandsSection";
import CategoriesMenu from "../../components/categaries_menu/CategoriesMenu";
import Categories from "../../components/Categories/Categories";
import Features from "../../components/features/Features";
import ProductGalleries from "../../components/product_galleries/ProductGalleries";
import Blogs from "../../components/blogs/Blogs";
import Testimonials from "../../components/testimonials/Testimonials";
import VideoSlider from "../../components/video_slider/VideoSlider";
import OutletBanner from "../../components/outlet_banner/OutletBanner";
import AboutUs from "../../components/about_us/AboutUs";
import Highlights from "../../components/highlights/Highlights";
import Model3D from "../../components/model3D/Model3D";
import { url } from "../../components/api/Url";
import UserContext from "../../context/UserContext";
import Seller_Categories_menu from "../template1/Seller_Categories_menu";
import Seller_banner from "../template1/Seller_banner";
import Seller_brand from "../template1/Seller_brand";
import Seller_three_model from "../template1/Seller_three_model";
import SellerVedio from "../template1/SellerVedio";
import SellerBlog from "../template1/SellerBlog";
import Seller_features from "../template1/Seller_features";
import SellerHighlight from "../template1/SellerHighlight";
import SellerTestimonials from "../template1/SellerTestimonials";
import Seller_Features_All from "../template1/Seller_Features_All";
import SellerNotice from "../template1/SellerNotice";
import SellerCategoriesMenu from "../template1/SellerCategoriesMenu";
import { CategoryProvider } from "../../components/categaries_menu/CategoryContext" //"../../components/categaries_menu/CategoryContext";
import ZFINALSQUARE from "../../../src/assets/images/ZFINALSQUARELatest.png"; //../../../src/assets/images/ZFINALSQUARELatest.png
import SellerCurate from "../template1/SellerCurate";
import Highlights2 from "../../components/highlights/Highlights2";


const SellerDetails = () => {
  const { id } = useParams();
  const [selectedHighlightId, setSelectedHighlightId] = useState(null);
  const [data, setData] = useState([])
  const [categoriesdata, setCategoriesdata] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [modelData, setModelData] = useState([]);
  const [videomodelData, setVideomodelData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const {
    sellerListdata,
    setSellerListdata,
    loader,
    error,
    setLoader,
    setError,
  } = useContext(UserContext);

  async function sellerList() {
    // const json = JSON.stringify({ seller_id: "85" });
    try {
      let response = await axios.post(url + "/app/v1/api/seller_list?id=" + localStorage.getItem('seller_id'));
      let res = await response.data;
      const data = res[0].json_component;
      const Jsonres = JSON.parse(data)
      setSellerListdata(res[0]);
      //console.log('seller list first' + JSON.stringify(res[0]))
      setData(Jsonres)
      setLoader(false);
      setError(false);
    } catch (error) {
      setError(true);
      console.log("ERROR MESSAGE :: ", error.message);
    }
  }

  async function fetch3DModels() {
    try {
      const response = await axios.post(`${url}/get_all_videos`, {
        seller_id: sellerid,
        video_type: "3dModel",
      });
      setModelData(response.data.message || []);
    } catch (error) {
      //console.error("Error fetching 3D models:", error);
      setError(true);
    }
  }

  const handleHighlightClick = (id) => {
    setSelectedHighlightId(id);
  };

  async function fetchVideoModels() {
    try {
      const response = await axios.post(`${url}/get_all_videos`, {
        seller_id: sellerid,
        video_type: "video",
      });
      // console.log('seller video res' + response.data.message);
      setVideomodelData(response.data.message || []);
    } catch (error) {
      console.error("Error fetching video:", error);
      setError(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAllData = async () => {
      setLoader(true);
      await Promise.all([
        sellerList(),
        //  fetch3DModels(),
        //  fetchVideoModels()
      ]);
      setLoader(false);
    };
    fetchAllData();
  }, [setLoader, setError]);
  // console.log("sellerListdata", sellerListdata);

  let sliderImages = sellerListdata?.slider_images;
  try {
    sliderImages = JSON.parse(sliderImages);
  } catch (error) {
    // console.error("Error parsing JSON:", error);
  }
  // console.log(sliderImages);

  /*   if (loader) {
      return (
         <h1 className="flex justify-center items-center h-screen text-3xl font-bold">
          Loading...
        </h1> 
      );
    }
    if (error) {
  
    } */

  // console.log("data2 is here",   data2.json_component);

  return (
    <>
         <h1 className="text-center h-[45px] relative top[0px] text-[16px] w-full px-[-20px] mb-[-30px] flex items-center justify-center"
        style={{ fontStyle: `${data?.component?.super_top_bar?.website_style}`, color: `${data?.component?.super_top_bar?.color}`, backgroundColor: `${data?.component?.super_top_bar?.backgroundcolor}` }}>
        {data?.component?.super_top_bar?.text}</h1>
    <div className="mx-[140px]">
 
      {
        isloading ?
          <>
            {/*   <h3> Loading</h3> */}
          </>
          :
          <>
            <CategoryProvider>
              <Seller_Categories_menu data={categoriesdata} sellerid={sellerid} />
              {/* <SellerNotice /> */}
              <SellerCategoriesMenu />
            </CategoryProvider>
          </>
      }

        <div className="mt-[60px]"> 
        <Seller_banner images={sliderImages} sellerid={sellerid} />
        </div>
    </div>
      {/* <Seller_three_model data={data} sellerid={sellerid} /> */}
      <div>
      <SellerCurate data={data} onHighlightClick={handleHighlightClick} />
      </div>
      {/*   curated collection */}
        {/* Features Section */}
        {/*    <section id="features" className="mt-10">
          <Features data={data} selectedHighlightId={selectedHighlightId} />
        </section> */}
      {/* end new */}
      <div className="mx-[100px]">
      <Seller_brand data={data} sellerid={sellerid} />
      </div>
      {/*  <Categories data={data} sellerid={sellerid} /> */}
      {/*  <SellerHighlight data={data} sellerid={sellerid} /> */}

      {/*  <Seller_Features_All data={data} sellerid={sellerid}/>
      <Seller_features data={data} sellerid={sellerid} /> */}
   
      {/*   <SellerVedio data={data} sellerid={sellerid} /> */} {/* videomodelData={videomodelData} */}
      {/* modelData={modelData} */}
      {/*   <OutletBanner data={data} sellerid={sellerid} /> */}
      <div className="mx-[100px]">
      <SellerBlog data={data} sellerid={sellerid} />

      </div>
      <div className="mx-[100px]">
      {/* <ProductGalleries /> */}
      <SellerTestimonials data={data} sellerid={sellerid}   title={data?.component?.testimonial.title}  />
      {/* About Us Section */}
      <AboutUs data={data} sellerid={sellerid} />
      
      </div>
   
    </>
  );
};

export default SellerDetails;
