import React from 'react'

const Aboutuscomponent = () => {

  return (
    <div className="mx-[100px]" style={{ marginTop: 100 }}>
    <h1 className="text-center h-[45px] relative top[0px] text-[16px] flex items-center justify-center">
      About Us
    </h1>
    </div>
  )
  }
export default Aboutuscomponent;